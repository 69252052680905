import * as React from "react"
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
    Button, chakra,
    Flex, Image,
    VStack
} from "@chakra-ui/react"
import { DownloadIcon } from "@chakra-ui/icons"

import { OptimizedImage } from "../../../components/optimized-image/optimized-image"


export function AwardAccordion({ imgs, masthead }) {
    return <Flex
        className={"award-article-container"}
        w={"full"}
        justify={"center"}
    >
        <Accordion
            className={"award-article-accordian"}
            w={"full"}
            maxW={"xl"}
            allowToggle
        >
            <AccordionItem
                className={"award-article-accordian-item"}
                border={"none"}
            >
                <Box
                    className={
                        "award-article-accordian-button-container"
                    }
                    px={4}
                >
                    <AccordionButton
                        className={"award-article-accordian-button"}
                        bg={"white"}
                        _hover={{
                            bg: "white",
                        }}
                        variant={"link"}
                        rounded={"md"}
                        shadow={"md"}
                        px={8}
                    >
                        <Flex
                            className={
                                "award-article-accordian-button-stack"
                            }
                            // as="span"
                            // flex="1"
                            w={"full"}
                            textAlign="left"
                            align={"center"}
                            // position={"relative"}
                            justify={"space-between"}
                        >
                            <Badge
                                className={
                                    "award-article-accordian-button-new-badge"
                                }
                                // top={"-14px"}
                                // left={"-14px"}
                                // position={"absolute"}
                                h={"26px"}
                                size={"sm"}
                                rounded={"lg"}
                                colorScheme={"green"}
                                borderColor={"green.500"}
                                borderStyle={"solid"}
                                borderWidth={"1px"}
                                fontWeight={"bold"}
                            >
                                NEW
                            </Badge>
                            <OptimizedImage
                                img={masthead}
                                imgProps={{
                                    className:
                                        "award-article-accordian-button-joongang-logo",
                                    h: "30px",
                                    display: "inline-block",
                                    alt: "중앙일보 로고",
                                }}
                            />
                            {/*<chakra.picture>*/}
                            {/*    <Image as="source" srcSet={imgs.masthead.avif} type="image/avif" />*/}
                            {/*    <Image as="source" srcSet={imgs.masthead.webp} type="image/webp"/>*/}
                            {/*    <Image*/}
                            {/*        className={"award-article-accordian-button-joongang-logo"}*/}
                            {/*        src={imgs.masthead.src}*/}
                            {/*        h={"30px"}*/}
                            {/*        display={"inline-block"}*/}
                            {/*        alt={"중앙일보 로고"}*/}
                            {/*    />*/}
                            {/*</chakra.picture>*/}

                            <Button
                                rightIcon={<DownloadIcon />}
                                as={"span"}
                                size={"sm"}
                                bg={"bg2.300"}
                                className={
                                    "award-article-accordian-button-text"
                                }
                                letterSpacing={"-.03rem"}
                                fontSize={"16px"}
                                rounded={"lg"}
                                shadow={"none"}
                                fontWeight={"bold"}
                            >
                                기사 읽기
                            </Button>
                        </Flex>
                        {/*<AccordionIcon/>*/}
                    </AccordionButton>
                </Box>
                <AccordionPanel
                    className={"award-article-accordian-panel"}
                >
                    <VStack
                        className={
                            "award-article-accordian-panel-stack"
                        }
                        w={"full"}
                        justify={"center"}
                    >
                        <chakra.picture>
                            <Image
                                as="source"
                                srcSet={imgs.avif}
                                type="image/avif"
                            />
                            <Image
                                as="source"
                                srcSet={imgs.webp}
                                type="image/webp"
                            />
                            <Image
                                className={"award-article-image"}
                                alt={"중앙일보 기사"}
                                pt={2}
                                shadow={"lg"}
                                src={imgs.src}
                            />
                        </chakra.picture>

                        {/*<Link*/}
                        {/*    target={"_blank"}*/}
                        {/*    color={"twitter.700"}*/}
                        {/*    py={3}*/}
                        {/*    isExternal*/}
                        {/*    textDecoration={"underline"}*/}
                        {/*    href={"https://www.joongang.co.kr/article/25121184"}>*/}
                        {/*    https://www.joongang.co.kr/article/25121184*/}
                        {/*    <ExternalLinkIcon mx={1} mb={"5px"}/>*/}
                        {/*</Link>*/}
                    </VStack>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    </Flex>
}