import * as React from "react"

import { Box } from "@chakra-ui/react"
import { Book } from "./book"
import momsCover from "../../assets/books/moms/moms_cover.jpg"
import { SectionHeader } from "../section/section-header"

const books = [
    {
        title: "맘들의 부업",
        subtitle: "남편 월급으로는 부족한 육아맘의 월300만원벌기 프로젝트",
        cover: momsCover,
        alt: "맘들의 부업",
        tags: ["1인사업가", "재택근무", "공동구매", "창업교육"],
        description:
            "대한민국 주부들의 삶은 오늘도 팍팍합니다. 남편이 가져다주는 월급만 보고서 아이에게 사주고 싶은 것을 참고, 자기 화장품 하나 살 때도 몇 번을 고민하는 주부들의 스트레스를 조금이라도 덜어주고 싶어요. " +
            "주부들도 열정과 의지만 있으면 충분히 집에서 돈을 벌 수 있고, 또 누구나 인스타 공구 판매를 할 수 있다는 데 이 책을 통해 조금이라도 가능성을 보는 이들이 있었으면 좋겠습니다.",
    },
]

export function Books() {
    return (
        <Box
            className={"books-section"}
            as={"section"}
            // mx={"auto"}
            w={"full"}
            py={{ base: 4, md: 0 }}
            // maxW={"4xl"}
            minH={"400px"}
            // bg={"red"}
            // paddingTop={0}
            bg={"white"}
            pb={{ base: 2, md: 6 }}
        >
            <SectionHeader
                title={"맘들의 부업 도서 출간"}
                description={
                    "3년간의 교육 히스토리와 꿀팁을 담은 꿈을더하다의 도서가 출간되었습니다"
                }
                titleProps={{ wordBreak: "keep-all", px: 2 }}
                // textProps={}
                px={{ base: 6 }}
                textAlign={"center"}
            />
            {books.map((book, key) => (
                <Book key={key} {...book} />
            ))}
        </Box>
    )
}
