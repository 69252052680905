import * as React from "react"
import {
    Box,
    Flex,
    useBreakpointValue,
} from "@chakra-ui/react"

import { SectionHeader } from "../section/section-header"
import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"

import imgs from "../../assets/awards/min"
import { AwardAccordion } from "./award-accordion"

export function Award() {
    // const subtitle = "창립 2년만에 공구교육 1위달성!"
    const pretitle = "2022년 - 창립 2년만에 공구교육 1위 달성"
    const subtitle = "2023년 - 소비자만족 브랜드 대상 1위 달성!"

    // console.log(imgs)

    // const img = useBreakpointValue({
    //     // base: imgs.mobile,
    //     // md: imgs.pc,
    //     base: imgs.mobile,
    // })

    const img2023 = useBreakpointValue({
        // base: imgs.mobile,
        // md: imgs.pc,
        base: imgs.award2023,
    })

    const imgProps = useBreakpointValue({
        base: {
            // w: "350px",
            // h: "424px",
        },
        md: {},
    })

    return (
        <Box
            className={"award-section"}
            as={"section"}
            mx={"auto"}
            py={{ base: 4, md: 6 }}
            maxW={"4xl"}
        >
            <SectionHeader
                pretitle={pretitle}
                subtitle={subtitle}
                pb={{ base: 6, md: 10 }}
                textProps={{
                    fontSize: { base: "xl", md: "2xl" },
                    align: "center",
                }}
            />


            <Flex justify={"center"} px={4}>
                <SkeletonOptimizedImage
                    img={img2023}
                    skeletonProps={{ mb: { base: 2, md: 4 } }}
                    imgProps={{
                        className: "award-image",
                        shadow: "lg",
                        mx: "auto",
                        mb: { base: 2, md: 4 },
                        ...imgProps,
                    }}
                />
            </Flex>
            <Flex justify={"center"} px={4}>
                <SkeletonOptimizedImage
                    img={imgs.ceremony2023}
                    skeletonProps={{ mb: { base: 2, md: 4 } }}
                    imgProps={{
                        className: "award-ceremony-image",
                        shadow: "lg",
                        mx: "auto",
                        mb: { base: 2, md: 4 },
                        ...imgProps,
                    }}
                />
            </Flex>

            <AwardAccordion imgs={imgs.article2023} masthead={imgs.masthead} />
        </Box>
    )
}
