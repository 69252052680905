import * as React from "react"
import {
    Box,
    Flex,
    VStack,
    HStack,
    Text,
    Heading,
    LinkOverlay,
    Icon,
    Stack,
    StackDivider,
    AspectRatio,
} from "@chakra-ui/react"
import { NavLink } from "react-router-dom"
import { format } from "date-fns"
import { ImHeart } from "react-icons/im"
import { isEmpty } from "lodash"
import he from "he"

import { SkeletonOptimizedImage } from "../../../components/optimized-image/skeleton-optimized-image"
import { Author } from "./author"
import { usePrefix } from "../../contexts/prefix-provider"

const authors = {
    ElMarket: "엘마켓",
    Mozzimong: "모찌몽베베",
    Dream: "꿈을더하다",
}

export function BlogListItem({ blog }) {
    const prefix = usePrefix()
    // console.log("BlogListItem", blog)

    return (
        <Box
            as={"article"}
            className={"blog-container"}
            position={"relative"}
            overflow={"hidden"}
            transition={"all 0.2s ease 0s"}
            bgColor={"gray.50"}
            textAlign={"left"}
            wordBreak={"keep-all"}
        >
            <LinkOverlay
                as={NavLink}
                className={"blog-link-overlay"}
                // href={blog.url}

                // DONE open in new tab
                // reloadDocument
                // target={"blank"}

                to={prefix(`/blog/${blog.logNo}`)}
            >
                <VStack
                    className={"blog-card"}
                    align={"flex-start"}
                    py={[2, 6]}
                    px={{ base: 4, md: 6, lg: 8 }}
                >
                    <Text
                        className={"blog-date"}
                        fontSize={"xs"}
                        letterSpacing={"tighter"}
                        color={"gray.600"}
                    >
                        {format(blog.date, "M월 d일 yyyy")}
                    </Text>
                    <HStack
                        className={"blog-details-main"}
                        justify={"space-between"}
                        align={"flex-start"}
                    >
                        <VStack
                            className={"blog-details"}
                            w={"67%"}
                            align={"flex-start"}
                        >
                            {!isEmpty(blog.tags) && (
                                <Stack
                                    className={"blog-tags"}
                                    direction={["row", "column", "row"]}
                                    pl={1}
                                    spacing={[2, 1, 3]}
                                    textTransform={"uppercase"}
                                    letterSpacing={"tight"}
                                    fontSize={"sm"}
                                    fontWeight={"bold"}
                                    mb={2}
                                    color={"penny.400"}
                                    divider={
                                        <StackDivider borderColor="gray.200" />
                                    }
                                >
                                    {!isEmpty(blog.tags) &&
                                        blog.tags.map((tag) => (
                                            <Text
                                                className={"blog-tag"}
                                                key={tag}
                                            >
                                                {tag}
                                            </Text>
                                        ))}
                                </Stack>
                            )}
                            <Heading
                                className={"blog-headline"}
                                as={"h3"}
                                fontWeight={"bold"}
                                fontSize={["md", null, "sm"]}
                                lineHeight={"base"}
                                mb={{ base: 2, md: 2 }}
                                color={"charcoal.700"}
                            >
                                {blog.title}
                            </Heading>
                            <Text
                                className={"blog-snippet"}
                                fontSize={["sm", null, null, "sm"]}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                                wordBreak={"break-all"}
                                mb={{ base: 4, md: 8 }}
                                sx={{
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: "2",
                                }}
                            >
                                {he.decode(blog.description)}
                            </Text>
                        </VStack>
                        <Flex w={"33%"} justify={"flex-end"}>
                            <AspectRatio
                                w={{ base: "120px", md: "140px", lg: "160px" }}
                                ratio={1}
                            >
                                <SkeletonOptimizedImage
                                    isLazy
                                    img={blog.image || { src: "", bucket: "" }}
                                    imgProps={{
                                        alt: "",
                                        w: {
                                            base: "120px",
                                            md: "140px",
                                            lg: "160px",
                                        },
                                        h: "auto",
                                    }}
                                />
                            </AspectRatio>
                        </Flex>
                    </HStack>
                    <Flex
                        className={"blog-footer"}
                        align={"baseline"}
                        justify={"space-between"}
                        fontSize={"sm"}
                        color={"gray.600"}
                        w={"full"}
                        pr={2}
                    >
                        <Author
                            className={"blog-author"}
                            author={blog.author || authors[blog.market]}
                        />
                        <Text className={"blog-no-of-likes"}>
                            {blog.views} <Icon as={ImHeart} color={"red.400"} />
                        </Text>
                    </Flex>
                </VStack>
            </LinkOverlay>
        </Box>
    )
}
