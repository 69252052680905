import * as React from "react"

import { VStack, Flex, Box, AvatarGroup, Avatar, Text } from "@chakra-ui/react"

import yes24 from "../../assets/books/bookstores/yes24.jpg"
import kyobo from "../../assets/books/bookstores/kyobo.png"
import aladdin from "../../assets/books/bookstores/aladdin.png"
import interpark from "../../assets/books/bookstores/interpark_doso.png"

const naverBooksLink =
    "https://search.shopping.naver.com/book/catalog/43802160627?query=%EB%A7%98%EB%93%A4%EC%9D%98%20%EB%B6%80%EC%97%85&NaPm=ct%3Dlp1tzjqo%7Cci%3Dceaf0cba4c0c32866722ffedc557556b3206f4fa%7Ctr%3Dboksl%7Csn%3D95694%7Chk%3D621c237a3eaad68d4f5a13e69e79f9f2c9716b2e"

const bookstores = [
    { img: kyobo, alt: "kyobo" },
    { img: interpark, alt: "interpark" },
    { img: yes24, alt: "yes24" },
    { img: aladdin, alt: "aladdin" },

    { img: interpark, alt: "interpark" },
    { img: yes24, alt: "yes24" },
    { img: aladdin, alt: "aladdin" },
    { img: kyobo, alt: "kyobo" },
    { img: interpark, alt: "interpark" },
    { img: yes24, alt: "yes24" },
    { img: aladdin, alt: "aladdin" },
    { img: kyobo, alt: "kyobo" },
    { img: interpark, alt: "interpark" },
    { img: yes24, alt: "yes24" },
    { img: aladdin, alt: "aladdin" },
    { img: kyobo, alt: "kyobo" },
]

// const manyBookstores =

export function Bookstores() {
    return (
        <Box as={"a"} href={naverBooksLink} w={"full"}>
            <Flex justify={{ base: "center", md: "flex-start" }} >
                <VStack spacing={0}>
                    <AvatarGroup
                        size={"md"}
                        max={4}
                        sx={{
                            ".chakra-avatar__excess": {
                                w: 10,
                                h: 10,
                                fontSize: "xs",
                                textAlign: "right",
                                marginInlineStart: "-.5rem",
                            },
                        }}
                    >
                        {bookstores.map((bs, key) => (
                            <Avatar key={key} src={bs.img} alt={bs.alt} w={10} h={10} />
                        ))}
                    </AvatarGroup>
                    <Text fontSize={"xs"} color={"gray.400"}>
                        전국 서점에서 구매 가능
                    </Text>
                </VStack>
            </Flex>

        </Box>
    )
}
