import {
    Button,
    CloseButton,
    Container,
    // Icon,
    Image,
    Square,

    Stack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react'
import * as React from 'react'
// import { HiOutlineShoppingCart } from 'react-icons/hi'
import childModelRecruitImg from "../../assets/banner/child_models_recruit.jpeg"


export function ChildModelRecruitBanner({ mallUrl }) {
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    })

    return <Container
        py={{
            base: '3',
            md: '2.5',
        }}
        position="relative"
    >
        {/*<CloseButton*/}
        {/*    display={{*/}
        {/*        sm: 'none',*/}
        {/*    }}*/}
        {/*    position="absolute"*/}
        {/*    right="2"*/}
        {/*    top="2"*/}
        {/*    onClick={() => setOpen(false)}*/}
        {/*/>*/}
        <Stack
            direction={{
                base: 'column',
                sm: 'row',
            }}
            justify="space-between"
            spacing={{
                base: '3',
                md: '2',
            }}
        >
            <Stack
                spacing="4"
                direction={{
                    base: 'column',
                    md: 'row',
                }}
                align={{
                    base: 'start',
                    md: 'center',
                }}
            >
                {!isMobile && (
                    <Square size="12" bg="whiteAlpha.500" borderRadius="md">
                        {/*<Icon color={"brand.500"} as={HiOutlineShoppingCart} boxSize="6"/>*/}
                        <Image src={childModelRecruitImg} borderRadius={"md"}/>
                    </Square>
                )}
                <Stack
                    direction={{
                        // base: 'column',
                        base: "row",
                        md: 'row',
                    }}
                    spacing={{
                        base: '4',
                        // base: "0.5",
                        md: '1.5',
                    }}
                    pe={{
                        base: '4',
                        sm: '0',
                    }}
                >{isMobile && (
                    <Square size="12" bg="white" borderRadius="md">
                        {/*<Icon color={"brand.500"} as={HiOutlineShoppingCart} boxSize="6"/>*/}
                        <Image src={childModelRecruitImg} borderRadius={"md"}/>
                    </Square>
                )}
                    <Stack
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                        spacing={{
                            base: "0.5",
                            md: '1.5',
                        }}
                        pe={{
                            base: '4',
                            sm: '0',
                        }}
                        align={{ md: "center" }}
                        fontFamily={"'Noto Sans KR'"}
                    >
                        <Text fontSize={{ base: "sm", md: "md" }} fontWeight="medium">아기모델 모집중🤎</Text>
                        <Text fontSize={{ base: "xs", md: "md" }} color="muted">매달 도전 가능! 우리 아기의 매력를 자랑해주세요. </Text>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                direction={{
                    // base: 'column',
                    sm: 'row',
                }}
                spacing={{
                    base: '3',
                    sm: '2',
                }}
                align={{
                    base: 'stretch',
                    sm: 'center',
                }}
            >
                <Button
                    as={"a"}
                    href={mallUrl}
                    shadow={{ md: "none" }}
                    variant="brand"
                    width="full"
                    rounded={"md"}
                    size={{ base: "sm", md: "md" }}>
                    아기모집 모델 참여하기
                </Button>
                <CloseButton
                    display={{
                        base: 'none',
                        // sm: 'inline-flex',
                    }}
                />
            </Stack>
        </Stack>
    </Container>
}