import * as React from "react"
import {
    Stack,
    VStack,
    List,
    ListItem,
    ListIcon,
    Badge,
    HStack,
    Text,
    Icon,
    useToken,
} from "@chakra-ui/react"
import { FaQuoteLeft } from "react-icons/fa"
import { VscVerified } from "react-icons/vsc"
import LazyLoad from "react-lazy-load"

import { OptimizedImage } from "../../../components/optimized-image/optimized-image"

export function InstructorProfile({
    img,
    bg,
    title,
    name,
    quote,
    qualifications,
    imgProps = {},
    color = "blue",
}) {

    const rtl = color === "blue"
    // let badgeColor = color === "blue" ? "#2a5482" : "brand.500"
    const badgeColor = color === "blue" ? "brand.400" : "brand.400"
    const textColor = color === "blue" ? "gray.700" : "gray.700"
    const stackProps = rtl
        ? {
              direction: { base: "column", lg: "row" },
          }
        : {
              direction: { base: "column", lg: "row-reverse" },
          }

    const [brand100] = useToken("colors", ["brand.100"])

    return (
        <Stack
            className={"instructor-profile-card"}
            direction={{ base: "column", lg: "row" }}
            justify={"space-between"}
            backgroundImage={bg.src}
            backgroundSize={"cover"}
            // background={"white"}
            rounded={"md"}
            minH={"250px"}
            mb={6}
            {...stackProps}
        >
            <VStack
                className={"instructor-profile-description"}
                p={{ base: 2, lg: 6 }}
                maxW={{ base: "inherit", lg: "60%" }}
            >
                <HStack
                    className={"instructor-profile-quote"}
                    textAlign={"left"}
                    px={3}
                    py={2}
                    align={"top"}
                >
                    <Icon
                        className={"instructor-profile-quote-icon"}
                        color={badgeColor}
                        as={FaQuoteLeft}
                        fontSize={"1.75rem"}
                    />
                    <Text
                        className={"instructor-profile-quote-text"}
                        ml={2}
                        fontSize={"xl"}
                        color={textColor}
                        fontWeight={500}
                        lineHeight={1.4}
                        wordBreak={"keep-all"}
                    >
                        {quote}
                    </Text>
                </HStack>
                <List
                    className={"instructor-profile-qualifications-list"}
                    textAlign={"left"}
                    w={"full"}
                    px={6}
                >
                    {qualifications.map((q, i) => (
                        <ListItem
                            className={"instructor-profile-qualification"}
                            fontWeight={"bold"}
                            fontSize={"lg"}
                            color={textColor}
                            key={i}
                        >
                            <ListIcon
                                as={VscVerified}
                                color={badgeColor}
                                fontSize={"1.3rem"}
                            />
                            {q}
                        </ListItem>
                    ))}
                </List>
            </VStack>
            <HStack
                className={"instructor-profile-image-container"}
                alignItems={"flex-end"}
                justify={"flex-end"}
                spacing={0}
                mt={{
                    base: 0,
                    md: "-70px !important",
                    lg: "0 !important",
                }}
                position={"relative"}
            >
                <HStack
                    className={"instructor-profile-name-container"}
                    position={"absolute"}
                    left={{ base: "30px", lg: "10px" }}
                    right={0}
                    bottom={"10px"}
                    align={"center"}
                    zIndex={1}
                >
                    <Badge
                        className={"instructor-profile-name-badge"}
                        mt={4}
                        fontSize={".8rem"}
                        fontWeight={500}
                        bg={badgeColor}
                        color={"white"}
                    >
                        {title}
                    </Badge>
                    {name && (
                        <Text
                            className={"instructor-profile-name"}
                            fontWeight={600}
                            color={textColor}
                            textShadow={`-1px 0px ${brand100}, 0px 1px ${brand100}, 1px 0px ${brand100}, 0px -1px ${brand100}`}
                            fontSize={"2.5rem"}
                        >
                            {name}
                        </Text>
                    )}
                </HStack>
                <LazyLoad height={300} offset={300}>
                    <OptimizedImage
                        img={img}
                        imgProps={{
                            className: "instructor-profile-image",
                            w: "auto",
                            maxH: "300px",
                            verticalAlign: "bottom",
                            ...imgProps,
                        }}
                    />
                </LazyLoad>

                {/*<Image*/}
                {/*    className={"instructor-profile-image"}*/}
                {/*    src={img}*/}
                {/*    verticalAlign={"bottom"}*/}
                {/*    w={"auto"}*/}
                {/*    maxH={"300px"}*/}
                {/*    {...imgProps}*/}
                {/*/>*/}
            </HStack>
        </Stack>
    )
}
