import * as React from "react"

import tc20240731 from "../components/terms/20240731/terms-and-conditions.md"
import rp20240731 from "../components/terms/20240731/refund-policy.md"
import pp20240731 from "../components/terms/20240731/privacy-policy.md"
import upi20240731 from "../components/terms/20240731/use-of-personal-information.md"
import cu20240731 from "../components/terms/20240731/contact-us.md"


import tc20230302 from "../components/terms/20230302/terms-and-conditions.md"
import rp20230302 from "../components/terms/20230302/refund-policy.md"
import pp20230302 from "../components/terms/20230302/privacy-policy.md"
import upi20230302 from "../components/terms/20230302/use-of-personal-information.md"
import cu20230302 from "../components/terms/20230302/contact-us.md"

import tc20230131 from "../components/terms/20230131/terms-and-conditions.md"
import rp20230131 from "../components/terms/20230131/refund-policy.md"
import pp20230131 from "../components/terms/20230131/privacy-policy.md"
import upi20230131 from "../components/terms/20230131/use-of-personal-information.md"
import cu20230131 from "../components/terms/20230131/contact-us.md"

import tc20230101 from "../components/terms/20230101/terms-and-conditions.md"
import rp20230101 from "../components/terms/20230101/refund-policy.md"
import pp20230101 from "../components/terms/20230101/privacy-policy.md"
import upi20230101 from "../components/terms/20230101/use-of-personal-information.md"
import cu20230101 from "../components/terms/20230101/contact-us.md"

import tc20210201 from "../components/terms/20210201/terms-and-conditions.md"
import rp20210201 from "../components/terms/20210201/refund-policy.md"
import pp20210201 from "../components/terms/20210201/privacy-policy.md"
import upi20210201 from "../components/terms/20210201/use-of-personal-information.md"
import cu20210201 from "../components/terms/20210201/contact-us.md"

const versions = [
    {
        name: "2024.07.31 (Current)",
        value: "2024.07.31",
        termsAndConditions: tc20240731,
        refundPolicy: rp20240731,
        privacyPolicy: pp20240731,
        useOfPersonalInformation: upi20240731,
        contactUs: cu20240731,
    },
    {
        name: "2023.03.02",
        value: "2023.03.02",
        termsAndConditions: tc20230302,
        refundPolicy: rp20230302,
        privacyPolicy: pp20230302,
        useOfPersonalInformation: upi20230302,
        contactUs: cu20230302,
    },
    {
        name: "2023.01.31",
        value: "2023.01.31",
        termsAndConditions: tc20230131,
        refundPolicy: rp20230131,
        privacyPolicy: pp20230131,
        useOfPersonalInformation: upi20230131,
        contactUs: cu20230131,
    },
    {
        name: "2023.01.01",
        value: "2023.01.01",
        termsAndConditions: tc20230101,
        refundPolicy: rp20230101,
        privacyPolicy: pp20230101,
        useOfPersonalInformation: upi20230101,
        contactUs: cu20230101,
    },
    {
        name: "2021.02.01",
        value: "2021.02.01",
        termsAndConditions: tc20210201,
        refundPolicy: rp20210201,
        privacyPolicy: pp20210201,
        useOfPersonalInformation: upi20210201,
        contactUs: cu20210201,
    },
]

export function useTermsAndConditions() {
    const [version, setVersion] = React.useState(versions[0])
    const [markdown, setMarkdown] = React.useState({
        termsAndConditions: "",
        refundPolicy: "",
        privacyPolicy: "",
        useOfPersonalInformation: "",
        contactUs: "",
    })

    React.useEffect(() => {
        fetch(version.termsAndConditions)
            .then((response) => response.text())
            .then((text) => {
                setMarkdown((prev) => {
                    return { ...prev, termsAndConditions: text }
                })
            })
        fetch(version.refundPolicy)
            .then((response) => response.text())
            .then((text) => {
                setMarkdown((prev) => ({ ...prev, refundPolicy: text }))
            })
        fetch(version.privacyPolicy)
            .then((response) => response.text())
            .then((text) => {
                setMarkdown((prev) => ({ ...prev, privacyPolicy: text }))
            })
        fetch(version.useOfPersonalInformation)
            .then((response) => response.text())
            .then((text) => {
                setMarkdown((prev) => ({
                    ...prev,
                    useOfPersonalInformation: text,
                }))
            })
        fetch(version.contactUs)
            .then((response) => response.text())
            .then((text) => {
                setMarkdown((prev) => ({ ...prev, contactUs: text }))
            })
    }, [version])

    return { markdown, setVersion, version, versions }
}
