import * as React from "react"
import { Box, Flex, Image, Text, Stack, VStack, Tag, HStack, TableContainer, Table, Tbody, Tr, Td } from "@chakra-ui/react"
import { Bookstores } from "./bookstores"

export function Book({ cover, alt, title, subtitle, tags, description }) {
    return (
        <Flex justify={"center"} w={"full"} p={{ base: 4 }}>
            <Stack
                className={"book-stack"}
                w={"full"}
                direction={{ base: "column", md: "row-reverse" }}
                justify={"center"}
                spacing={{ base: 4, md: 8 }}
                maxW={"4xl"}
                m={"auto"}
            >
                <Box
                    position={"relative"}
                    display={"inline-block"}
                    verticalAlign={"middle"}
                    className={"book-cover-area"}
                    maxW={"450px"}
                    m={"auto"}
                    // w={{base: "full", md: "50%"}}
                >
                    <Box
                        borderRadius={"2px"}
                        boxShadow={
                            "0 6px 20px 0 rgba(0,0,0,.05), 1px 1px 6px 0 rgba(0,0,0,.04)"
                        }
                        className={"book-cover-shadow-box"}
                        _before={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            width: "2px",
                            backgroundColor: "rgba(0,0,0,.2)",
                            content: "''",
                        }}
                        _after={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: { base: "4px", md: "6px" },
                            width: "6px",
                            backgroundImage:
                                "linear-gradient(90deg,transparent 18%,rgba(0,0,0,.2) 58%,transparent 96%)",
                            content: "''",
                        }}
                    >
                        <Image src={cover} alt={alt} />
                    </Box>
                </Box>
                <VStack
                    justify={"top"}
                    w={{ base: "full", md: "50%" }}
                    spacing={0}
                    align={"center"}
                    pt={{ base: 0, md: 2 }}
                >
                    <Box pb={4} maxW={"450px"}>
                        <Tag verticalAlign={"text-bottom"} mr={2}>
                            신간
                        </Tag>
                        <Text
                            as={"span"}
                            fontSize={"2xl"}
                            fontWeight={"bold"}
                            paddingEnd={3}
                            letterSpacing={"tighter"}
                        >
                            {title}
                        </Text>

                        <Text
                            as={"span"}
                            fontSize={"md"}
                            color={"gray.600"}
                            letterSpacing={"tighter"}
                            wordBreak={"keep-all"}
                        >
                            {subtitle}
                        </Text>
                    </Box>
                    <Box pb={4} maxW={"450px"}>
                        <VStack>
                            <HStack w={"full"}>
                                { tags.map((tag, idx) => <Tag
                                    key={idx}
                                    borderColor={"brand.800"}
                                    borderStyle={"solid"}
                                    borderWidth={"1px"}
                                    bg={"white"}
                                >{ tag }</Tag>) }
                            </HStack>
                            <TableContainer w={"full"}>
                                <Table size={"sm"}>
                                    <Tbody>
                                        <Tr>
                                            <Td>저자</Td>
                                            <Td>안예람, 이수연</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>출판</Td>
                                            <Td>OHK</Td>
                                        </Tr>
                                        <Tr>
                                            <Td>발행</Td>
                                            <Td>2023년 10월 10일</Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </TableContainer>
                            <Text
                                fontSize={"sm"}
                                color={"gray.700"}
                                wordBreak={"keep-all"}
                            >
                                {description}
                            </Text>

                        </VStack>

                    </Box>
                    <Bookstores />
                </VStack>
            </Stack>
        </Flex>
    )
}
